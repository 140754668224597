<template>
  <match-media v-slot="{ mobile }">
    <div class="customer-edit">
      <div class="customer-edit__header">
        <v-breadcrumbs :items="$options.CUSTOMER_EDIT_BREADCRUMBS" class="customer-edit__breadcrumbs" />
        <v-page-title class="customer-edit__title">{{ customerData.name }}</v-page-title>
      </div>
      <v-popover-actions v-if="mobile" class="customer-edit__add-history-popover">
        <v-button class="customer-edit__add-history-button" primary>Добавить запись</v-button>
        <template #actions="{ onClick }">
          <v-button-wide @click="onClick(openMobileHistoryForm($options.COMMENT))">Комментарий</v-button-wide>
          <v-button-wide @click="onClick(openMobileHistoryForm($options.OUTGOING_CALL))">Исходящий лид</v-button-wide>
          <v-button-wide @click="onClick(openMobileHistoryForm($options.SHOW))">Показ</v-button-wide>
        </template>
      </v-popover-actions>
      <div class="customer-edit__content">
        <div v-if="!loading" class="customer-edit__sections-wrapper">
          <v-tabs v-if="mobile" v-model="mobileTab" class="interaction-history-form__tabs" primary>
            <v-tab v-for="(tab, index) of $options.CUSTOMER_EDIT_MOBILE_TABS_MAP" :key="index" :name="tab.value">
              {{ tab.label }}
            </v-tab>
          </v-tabs>
          <div
            v-show="!mobile || mobileTab === $options.CUSTOMER_EDIT_MOBILE_TABS.INFORMATION"
            class="customer-edit__information-block"
          >
            <customer-edit-about
              class="customer-edit__section"
              v-bind="customerData"
              :changes="customerDraftChanges"
              @edit="showEditDialog"
            ></customer-edit-about>
          </div>
          <div
            v-show="!mobile || mobileTab === $options.CUSTOMER_EDIT_MOBILE_TABS.HISTORY"
            class="customer-edit__history-block"
          >
            <customer-edit-viewed-objects
              v-if="customerData.adverts && customerData.adverts.length"
              v-bind="customerData"
              class="customer-edit__section"
            ></customer-edit-viewed-objects>
            <v-interaction-history
              ref="customerHistory"
              class="customer-edit__section"
              entity="customer"
              :default-agent="customerData.agent"
            ></v-interaction-history>
          </div>
        </div>
      </div>
      <customer-edit-dialog
        :visible.sync="editDialogVisible"
        :customer-data="customerData"
        @submitted="editCustomerSubmit"
      />
      <customer-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="customerDraftData"
        :customer-data="customerData"
        @submitted="editCustomerSubmit"
      />
    </div>
  </match-media>
</template>

<script>
import { SHOW, OUTGOING_CALL, COMMENT } from '@/constants/ownerInteractionHistory'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { customersService } from '@/services/http'
import redirectIfNetworkIssue from '@/router/utils'
import { loadingService } from '@/services/loading'
import { MatchMedia } from 'vue-component-media-queries'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import { formatDate } from '@/utils/formatters'
import CustomerEditDialog from '@/components/Customer/EditDialog.vue'
import CustomerEditPendingDialog from '@/components/Customer/EditPendingDialog.vue'
import CustomerEditAbout from '@/components/Customer/Edit/About.vue'
import VInteractionHistory from '@/components/common/InteractionHistory/VInteractionHistory.vue'
import CustomerEditViewedObjects from '@/components/Customer/Edit/ViewedObjects.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButton from '@/components/common/VButton.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'

const CUSTOMER_EDIT_BREADCRUMBS = [
  {
    text: 'Покупатели',
    to: { name: 'customers' }
  },
  {
    text: 'Страница покупателя',
    disabled: true
  }
]
const CUSTOMER_EDIT_MOBILE_TABS = {
  INFORMATION: 'information',
  HISTORY: 'history'
}
const CUSTOMER_EDIT_MOBILE_TABS_MAP = [
  { label: 'История', value: CUSTOMER_EDIT_MOBILE_TABS.HISTORY },
  { label: 'Информация', value: CUSTOMER_EDIT_MOBILE_TABS.INFORMATION }
]

export default {
  SHOW,
  OUTGOING_CALL,
  COMMENT,
  CUSTOMER_EDIT_MOBILE_TABS,
  CUSTOMER_EDIT_MOBILE_TABS_MAP,
  CUSTOMER_EDIT_BREADCRUMBS,
  name: 'CustomerEdit',
  components: {
    VButtonWide,
    VButton,
    VPopoverActions,
    VTab,
    VTabs,
    CustomerEditViewedObjects,
    VInteractionHistory,
    CustomerEditAbout,
    CustomerEditPendingDialog,
    CustomerEditDialog,
    MatchMedia,
    VPageTitle,
    VBreadcrumbs
  },
  data() {
    return {
      editDialogVisible: false,
      editPendingDialogVisible: false,
      customerData: {},
      customerDraftData: {},
      customerDraftChanges: {},
      loading: false,
      allCustomerObjectsList: [],
      customerStatuses: [],
      customerCallSources: [],
      ownerDraftChanges: {},
      mobileTab: CUSTOMER_EDIT_MOBILE_TABS.HISTORY
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    customerId() {
      return this.$route.params.customerId
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  created() {
    this.loading = true
    const CustomerCallSourcesRequest = this.fetchCustomerCallSources()
    const CustomerStatusesRequest = this.fetchCustomerStatuses()

    Promise.all([CustomerCallSourcesRequest, CustomerStatusesRequest])
      .then(() => {
        this.fetchCustomerById(this.customerId)
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    openMobileHistoryForm(type) {
      this.$refs.customerHistory.openMobileHistoryForm(type)
    },
    formattingCustomerData(data) {
      return {
        ...data,
        callSource: this.getCustomerCallSource(data),
        status: this.getCustomerStatus(data),
        agent: data.customerProfile.agent
      }
    },
    getCustomerCallSource({ customerProfile }) {
      if (this.customerCallSources.length) {
        const { callSource: currentCallSources } = customerProfile
        if (currentCallSources) {
          const source = this.customerCallSources.find(
            callSource => callSource.id === (currentCallSources.id || currentCallSources)
          )
          if (source) return source
        }
      }
      return 'Не выбран'
    },
    getCustomerStatus({ customerProfile }) {
      if (this.customerStatuses.length) {
        const { status: currentStatus } = customerProfile
        if (currentStatus) {
          const statusObject = this.customerStatuses.find(status => status.id === (currentStatus.id || currentStatus))
          if (statusObject) return statusObject
        }
      }
      return ''
    },
    editCustomerSubmit(newCustomerData) {
      this.customerData = this.formattingCustomerData(newCustomerData)
      this.customerDraftChanges = {}
      this.fetchCustomerEditChanges(newCustomerData)
    },
    showEditDialog() {
      if (this.customerData.hasPending) this.editPendingDialogVisible = true
      else this.editDialogVisible = true
    },
    formatDate,
    updateCustomer(params) {
      return customersService.update(this.customerData.id, params).then(newCustomerData => {
        this.customerData = this.formattingCustomerData(newCustomerData)
        this.fetchCustomerEditChanges(newCustomerData)
      })
    },
    goBack() {
      this.$router.push({ name: 'customers' })
    },
    fetchCustomerEditChanges({ hasPending }) {
      if (hasPending) {
        this.loading = true
        Promise.all([this.fetchCustomerDraft(), this.fetchCustomerDraftChanges()]).finally(() => {
          this.loading = false
        })
      }
    },
    fetchCustomerDraft() {
      return customersService.getCustomerDraft(this.customerData.versionId).then(draftData => {
        this.customerDraftData = this.formattingCustomerData(draftData)
      })
    },
    fetchCustomerDraftChanges() {
      return customersService.getCustomerDraftChanges(this.customerData.versionId).then(({ content }) => {
        this.customerDraftChanges = content
      })
    },
    fetchCustomerById(id) {
      return customersService
        .getCustomerById(id)
        .then(customerData => {
          this.customerData = this.formattingCustomerData(customerData)
          this.fetchCustomerEditChanges(customerData)
        })
        .catch(redirectIfNetworkIssue)
    },
    fetchCustomerStatuses() {
      return customersService.getCustomerStatusesMap().then(statuses => {
        this.customerStatuses = statuses
      })
    },
    fetchCustomerCallSources() {
      return customersService.getCallSourcesMap().then(callSources => {
        this.customerCallSources = callSources
      })
    }
  }
}
</script>
